@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,700&display=swap');

*{
    margin: 0;
    padding: 0;
}
body {
    background-color: #F4F4F9;
    font-family: 'Roboto', sans-serif;
}


.hero-wrapper-class {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70vh;
}

.button{
    background-color: #7776D3;
    color: #F4F4F9;
    padding: .6rem 1.5rem;
    border:none;
    font-size: 1rem;
    border-radius: 23px;
    cursor: pointer;
}

.card {
    padding: 1rem;
    width: 80%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid #F4F4F9;
    border-radius: 23px;
    background-color: white;
    background-image: url('./assets/dorde.svg');
}

.card .upper-section{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    padding: .6rem 1rem;
    border-radius: 15px;
    background-color: #7776D3;
    font-size: 1rem;
    color: #F4F4F9;
}
.card .upper-section p{
    padding-right: 3px;
}


.card .middle-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card .middle-section .button{
    margin-top: 1.5rem;
    padding:.6rem 2rem
}
.card .headingDiv{
}
.card .heading{
    font-weight: bold;
    font-size: 3.5rem;
}
.card .heading span {
    position: relative;
    color: #7776D3;
}

/*css for red line*/

/*.card .heading span ::after{*/
/*    display: block;*/
/*    content: '';*/
/*    width: 100%;*/
/*    height: 1px;*/
/*    background-color: red;*/
/*    border-radius: 23px;*/
/*    position: absolute;*/
/*    bottom:0;*/
/*    left: 0;*/
/*}*/

.card .subheading-div{
    margin-top: .6rem;
}
.card .subheading-div .subheading{
    font-size: 1rem;
    color: grey;
}